/* eslint-disable no-useless-escape */
bcl.s.searchHotel = {
  props: {
    cssSelector: "search-hotel-JS",
    resultSelector: ".result-JS",
    searchSelector: ".search-JS",
    inputSelector: ".hotel-id-JS",
    cssSelectorWithDestinations: "search-hotel-withDestinations-JS",
    classLoading: "mod--loading-dots",
    data: {},
    openAccordionMobile: "openMobileAccordeon-JS",
    checkinForm: "checkin-form",
  },

  preInit: function () {
    bcl.u.docReady(bcl.s.searchHotel.init, true);
  },

  init: function () {
    const $containers = document.getElementsByClassName(bcl.s.searchHotel.props.cssSelector);

    if (!$containers) {
      return;
    }

    bcl.u.forEach($containers, bcl.s.searchHotel.initItem);
  },

  initItem: function ($container) {
    const id = bcl.s.searchHotel.initSearch($container);

    if (!id) {
      return;
    }

    bcl.s.searchHotel.initInputId(id, $container);

    bcl.s.searchHotel.initListener(id, $container);

    bcl.s.searchHotel.initResultNodes(id, $container);
  },

  initSearch: function ($container) {
    const $searchInput = $container.querySelector(bcl.s.searchHotel.props.searchSelector);

    if (!$searchInput) {
      return;
    }

    const id = $searchInput.dataset.id;

    if (!id) {
      return;
    }

    bcl.s.searchHotel.props.data[id] = {
      $searchInput: $searchInput,
      $container: $container,
    };

    return id;
  },

  initInputId: function (id, $container) {
    const $input = $container.querySelector(bcl.s.searchHotel.props.inputSelector);

    if (!$input) {
      return;
    }

    bcl.s.searchHotel.props.data[id].$hotelInput = $input;
  },

  initListener: function (id, $container) {
    let typingTimer;
    const $searchInput = bcl.s.searchHotel.props.data[id].$searchInput;

    $searchInput.addEventListener("keyup", function () {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function () {
        bcl.s.searchHotel.searchEvent($searchInput, $container);
      }, 500);
    });

    $searchInput.addEventListener("keydown", function (e) {
      clearTimeout(typingTimer);
      bcl.c.fastbooking.destination.selectDestinationOnPressKey(e);
    });

    $searchInput.addEventListener("focus", function () {
      if (!this.readOnly) {
        this.value = "";
      }
    });

    $searchInput.addEventListener("focusout", function () {
      this.value = bcl.s.searchHotel.props.lastLabel || "";
    });
  },

  initResultNodes: function (id) {
    const $resultContainer = bcl.s.searchHotel.props.data[id].$container.querySelector(bcl.s.searchHotel.props.resultSelector);

    if (!$resultContainer) {
      return;
    }

    bcl.s.searchHotel.props.data[id].$resultContainer = $resultContainer;
    bcl.s.searchHotel.props.data[id].$resultUlContainer = $resultContainer.querySelector("ul");
  },

  searchEvent: function ($searchInput, $container) {
    bcl.u.addClass($searchInput, bcl.s.searchHotel.props.classLoading);

    const value = $searchInput.value;

    if (value.length >= ($searchInput.dataset.searchlimit || 3)) {
      const url = $searchInput.dataset.url || $container.dataset.url,
        data = {
          q: value,
        };

      bcl.ajax.postRequest({
        data: data,
        url: url,
        callback: bcl.s.searchHotel.searchCallback,
        itemCallback: $searchInput,
      });
    }
  },

  searchCallback: function (response, $searchInput) {
    const data = JSON.parse(response.response);

    bcl.s.searchHotel.props.data[$searchInput.dataset.id].$resultUlContainer.innerHTML = "";

    bcl.s.searchHotel.resultPrint(data.results, $searchInput.dataset.id, "hotel");

    if (bcl.u.containsClass(bcl.s.searchHotel.props.data[$searchInput.dataset.id].$searchInput, bcl.s.searchHotel.props.cssSelectorWithDestinations)) {
      bcl.s.searchHotel.resultPrint(data.results, $searchInput.dataset.id, "destination", true);
    }

    bcl.u.removeClass($searchInput, bcl.s.searchHotel.props.classLoading);
  },

  hotelSearchCallback: function (response, $searchInput) {
    const data = JSON.parse(response.response);
    const $ulContainer = $searchInput.closest(".container-tabs-JS").querySelector("ul");
    $ulContainer.innerHTML = "";

    const boldMatch = function (str, match) {
      const regex = new RegExp(match, "gi");
      let nMatchedChars = 0;
      const matches = str.match(regex);
      if (matches && matches.length) {
        matches.forEach(function (match) {
          str = str.replace(match, `<strong>${match}</strong>`);
          nMatchedChars += match.length;
        });
      }
      return { str, nMatchedChars };
    };

    data.forEach(function (entry) {
      const terms = $searchInput.value
        .replaceAll(/(\+|\-|\&|\||\!|\(|\)|\{|\}|\[|\]|\^|"|\'|\~|\*|\?|\:|\\)/g, "")
        .trim()
        .split(" ");
      let nMatchChars = 0;
      terms.forEach((term) => {
        const matchData = boldMatch(entry.label, term);
        entry.label = matchData.str;
        nMatchChars += matchData.nMatchedChars;
      });
      const nChars = entry.label.replaceAll(/<(\/)*strong>/g, "").length;
      entry.matchPercent = (nMatchChars / nChars) * 100;
    });
    data.sort(function (e1, e2) {
      return e2.matchPercent - e1.matchPercent || (e1.label < e2.label ? -1 : e1.label > e2.label ? 1 : 0);
    });
    data.forEach(function (entry) {
      $ulContainer.innerHTML += `<li class="px-2 mod--hover-blue" role="button" data-hotel-id="${entry.id}">${entry.label}</li>`;
    });

    bcl.u.removeClass($searchInput, bcl.s.searchHotel.props.classLoading);
  },

  resultPrint: function (items, id, type, append) {
    if (items.length !== 0) {
      bcl.u.removeClass(bcl.s.searchHotel.props.data[id].$resultUlContainer.parentNode, "p-0");
      bcl.u.removeClass(bcl.s.searchHotel.props.data[id].$resultContainer, "hidden");
    } else {
      bcl.u.addClass(bcl.s.searchHotel.props.data[id].$resultUlContainer.parentNode, "p-0");
      bcl.u.addClass(bcl.s.searchHotel.props.data[id].$resultContainer, "hidden");
    }

    if (typeof append != "undefined" && !append) {
      bcl.s.searchHotel.props.data[id].$resultUlContainer.innerHTML = "";
    }

    bcl.u.forEach(items, function (item) {
      bcl.s.searchHotel.props.itemMatch = null;
      bcl.s.searchHotel.lookingForMatchElement(item.destinations);
      item.destinations = bcl.s.searchHotel.props.itemMatch ? bcl.s.searchHotel.props.itemMatch : item.destinations;
      const itemObj = {
        parentItem: item.destinations,
        id: item.destinations.id,
        parentKey: item.key ?? bcl.c.fastbooking.destination.props.accordionDefaultParentValue,
        countryName: item.destinations.title,
        itemKey: item.destinations.key,
        item: item.destinations,
        value: bcl.s.searchHotel.props.data[id]?.$searchInput.value,
        key: "destination",
        match: item.destinations.match,
        list: bcl.s.searchHotel.props.data[id].$resultUlContainer,
      };

      bcl.s.searchHotel.addItemInList(item, id, type, itemObj);
    });
  },

  printHotelResult: function (items, $input, $ulContainer, $result, type, id) {
    bcl.u[items.length ? "removeClass" : "addClass"]($ulContainer.parentNode, "p-0");
    bcl.u[items.length ? "removeClass" : "addClass"]($result, "hidden");

    bcl.u.forEach(items, function (item) {
      bcl.s.searchHotel.props.itemMatch = null;
      bcl.s.searchHotel.lookingForMatchElement(item.destinations);
      item.destinations = bcl.s.searchHotel.props.itemMatch ? bcl.s.searchHotel.props.itemMatch : item.destinations;
      const itemObj = {
        parentItem: item.destinations,
        id: item.destinations.id,
        parentKey: item.key ?? bcl.c.fastbooking.destination.props.accordionDefaultParentValue,
        countryName: item.destinations.title,
        itemKey: item.destinations.key,
        item: item.destinations,
        value: bcl.s.searchHotel.props.data[id].$searchInput.value,
        key: "destination",
        match: item.destinations.match,
        list: bcl.s.searchHotel.props.data[id].$resultUlContainer,
      };

      bcl.s.searchHotel.addItemInList(item, id, type, itemObj);
    });
  },

  addItemInList: function (item, id, type, obj) {
    let $node;

    if (type === "hotel" || type === "destination") {
      bcl.s.searchHotel.lookingForElements(obj, type);
    }

    $node &&
      $node.addEventListener("click", function () {
        bcl.s.searchHotel.clickInItem(item, id, type);
      });
  },

  lookingForSubDestinationsHotels: function ({ parentItem, parentKey, items, list, value, key, listHotels, countryName, match, type }) {
    bcl.u.forEach(items, function (subItem) {
      let valueToObj = "hide";
      if (subItem.disableCheckin && subItem.disableCheckin === "true") {
        valueToObj = "show";
      }

      const itemObj = {
        disableCheckIn: valueToObj,
        parentItem: parentItem,
        parentKey: parentKey,
        item: subItem,
        itemKey: subItem.key,
        value: value,
        key: key,
        hide: false,
        listHotels: listHotels,
        countryName: countryName,
        list,
        match: type === "hotel" ? match || subItem.match : subItem.match,
        id: key === "hotel" ? subItem.hotel.id : subItem.id,
      };
      bcl.s.searchHotel.lookingForElements(itemObj, type);
    });
  },

  lookingForMatchElement: function (item) {
    if (item.match) {
      bcl.s.searchHotel.props.itemMatch = item;
    } else {
      if (item.accordionItems) {
        bcl.s.searchHotel.lookingForMatchSubElement(Object.values(item.accordionItems), item);
      } else if (item.destinations) {
        bcl.s.searchHotel.lookingForMatchSubElement(Object.values(item.destinations), item);
      } else if (item.pages) {
        bcl.s.searchHotel.lookingForMatchSubElement(item.pages, item);
      }
    }
  },

  lookingForMatchSubElement: function (items, parentItem) {
    bcl.u.forEach(items, function (item) {
      if (item.match) {
        bcl.s.searchHotel.props.itemMatch = parentItem;
      } else {
        bcl.s.searchHotel.lookingForMatchElement(item);
      }
    });
  },

  lookingForElements: (obj, type) => {
    const isCheckinForm = document.querySelector("#" + bcl.s.searchHotel.props.checkinForm);
    if (obj.disableCheckIn && obj.disableCheckIn == "show" && isCheckinForm) {
      return;
    }

    const { list, key, value, match, item, itemKey } = obj,
      label = item.title || item.label || item.hotelPublicName;
    let $node = null;

    const id = list.closest("." + bcl.c.checkinForm.props.constainerSearchSelector).querySelector("." + bcl.c.checkinForm.props.searchJS).dataset.id;

    if (type === "hotel" && key === "hotel" && list.children.length <= 8) {
      $node = bcl.s.searchHotel.generateNodeItemInList(list, value, label, item.id, type, { filter: true, match }, obj);

      $node &&
        $node.addEventListener("click", function () {
          bcl.s.searchHotel.clickInItem(item, id, type);
        });
    }

    item.accordionItems && bcl.s.searchHotel.lookingForSubDestinationsHotels({ parentItem: item, parentKey: itemKey, items: Object.values(item.accordionItems), value, match, key, type, hide: false, list });

    item.destinations && bcl.s.searchHotel.lookingForSubDestinationsHotels({ parentItem: item, parentKey: itemKey, items: Object.values(item.destinations), value, match, key, type, hide: false, list });

    item.pages && bcl.s.searchHotel.lookingForSubDestinationsHotels({ parentItem: item, parentKey: itemKey, items: item.pages, value, match, type, key: "hotel", hide: false, list });

    if (type === "destination" && key !== "hotel") {
      $node = bcl.s.searchHotel.generateNodeItemInList(list, value, label, item.id, type, { filter: true, match }, obj);

      $node &&
        $node.addEventListener("click", function () {
          bcl.s.searchHotel.clickInItem(item, id, type);
        });
    }
  },

  prepareLiItem: function (percentageMatched, { hide, id, type, parentKey, currentItemKey, rankingNumber, externalRankingNumber }, title, customClasses) {
    const $node = document.createElement("li");

    $node.setAttribute("role", "button");
    $node.setAttribute("data-percentage-match", percentageMatched.toString());

    bcl.u.addClass($node, "px-2");
    hide && bcl.u.addClass($node, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);
    customClasses &&
      customClasses.length > 0 &&
      bcl.u.forEach(customClasses, function (currentClass) {
        bcl.u.addClass($node, currentClass);
      });

    if (id) {
      $node.dataset.id = id;
    }

    if (type) {
      $node.dataset.type = type;
    }

    $node.dataset.parentElement = parentKey;
    $node.dataset.currentElement = currentItemKey;
    $node.dataset.ranking = rankingNumber;
    $node.dataset.externalRanking = externalRankingNumber;

    $node.innerHTML = title;

    return $node;
  },

  handlerBold: function (labelSplit, title, matched) {
    for (let i = 0; i < labelSplit?.length; i++) {
      const obj = bcl.u.putInBold(title, labelSplit[i]);

      title = obj.value;
      matched = matched || obj.matched;
    }
    return { title, matched };
  },

  generateNodeItemInList: function ($parent, value, label, id, type, { filter, match }, { hide, parentKey, currentItemKey, currentItem }) {
    const labelSplit = value?.split(" "),
      rankingNumber = currentItem?.ranking || 0,
      externalRankingNumber = currentItem?.externalRanking || 999;

    let title = label,
      matched = match || false,
      percentageMatched = 0;

    const { title: titleBold, matched: matchedBold } = bcl.s.searchHotel.handlerBold(labelSplit, title, matched);
    title = titleBold;
    matched = matchedBold;

    if (filter && !matched) {
      return false;
    }

    percentageMatched = value ? bcl.s.searchHotel.getPercentageOfMatch(label.split(".")[0], value) : 0;

    const $node = bcl.s.searchHotel.prepareLiItem(percentageMatched, { hide, id, type, parentKey, currentItemKey, rankingNumber, externalRankingNumber }, title, ["mod--hover-blue"]);

    $node.dataset.facets = currentItem?.facets?.toString();

    if ($node) {
      if (currentItem?.hotel?.parentResort) {
        $node.dataset.parentResort = currentItem.hotel.parentResort;
        bcl.u.addClass($node, "resort-child");
      }
      if (currentItem?.isResort) {
        $node.dataset.resort = currentItem.isResort.selector;
        bcl.u.addClass($node, "resort-parent");
      }
    }

    $parent.appendChild($node);

    return $node;
  },

  setBackgroundWhenMatch: function (percentageMatched, $label, $element) {
    if (percentageMatched >= bcl.c.fastbooking.destination.props.percentageToClick) {
      bcl.c.fastbooking.destination.props.elementWithPercentageFound = !bcl.c.fastbooking.destination.props.elementWithPercentageFound ? $label : bcl.c.fastbooking.destination.props.elementWithPercentageFound;
      bcl.c.fastbooking.destination.props.elementIndexFlagManualSelected = null;
      bcl.c.fastbooking.destination.setBackgroundColorLikeHover($element, false);
    } else {
      bcl.c.fastbooking.destination.cleanBackgroundClass($element);
    }
  },

  prepareAccordionItem: function (percentageMatched, hide, id, type, parentKey, currentItemKey, title) {
    const $node = bcl.c.fastbooking.destination.props.accordionNodeCloned?.cloneNode(true),
      $label = $node.querySelector("." + bcl.c.fastbooking.destination.props.accordionLabelClass),
      $accordionToggle = $node.querySelector(".accordion-toggle-JS");

    $label.setAttribute("data-percentage-match", percentageMatched.toString());
    bcl.u.addClass($accordionToggle, "mod--hover-grey");
    bcl.u.addClass($accordionToggle, "px-2");

    hide && bcl.u.addClass($node, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);

    if (id) {
      $label.dataset.id = id;
    }

    if (type) {
      $label.dataset.type = type;
    }

    $label.dataset.parentElement = parentKey;
    $label.dataset.currentElement = currentItemKey;

    $label.innerHTML = title;
    return $node;
  },

  openAccordionUntilThisElement: function (currentNode) {
    let found = true;
    do {
      const parentLiNode = bcl.u.closest(currentNode, "li");

      parentLiNode && !bcl.u.containsClass(parentLiNode, "active") && bcl.u.addClass(parentLiNode, "active");
      parentLiNode && bcl.u.mobile.isMobile() && bcl.u.containsClass(parentLiNode, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass) && bcl.u.removeClass(parentLiNode, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);

      currentNode = parentLiNode;

      found = currentNode;
    } while (found);
  },

  createFirstElementInHotels: function (key, item, listHotels, value, parentKey, itemKey) {
    const label = bcl.c.fastbooking.destination["getLabel" + key](item),
      $node = bcl.s.searchHotel.generateNodeItemInList(listHotels, value, label, null, null, {}, { parentKey, currentItemKey: itemKey, currentItem: item }),
      $filterContainerClone = bcl.c.fastbooking.destination.props.$searchContainer.querySelector("." + "filter-count-JS")?.cloneNode(true),
      allLabel = bcl.c.fastbooking.commonProps.fastbooking.$container.dataset.allLabel;

    $node.dataset.type = key;
    bcl.u.addClass($node, bcl.c.fastbooking.destination.props.submenuSearchFields.destinationElementInHotelSelector);

    bcl.c.fastbooking.destination.addClickInItem($node, item, itemKey);

    const destinationsNodeInHotel = listHotels.querySelectorAll("." + bcl.c.fastbooking.destination.props.submenuSearchFields.destinationElementInHotelSelector);

    bcl.u.forEach(destinationsNodeInHotel, (destinationNode) => {
      listHotels.removeChild(destinationNode);
    });

    const spanAllLabel = $node.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.allLabelClass);

    if (spanAllLabel) {
      bcl.u.removeClass(spanAllLabel, bcl.c.fastbooking.commonProps.fastbooking.hiddenClass);
      spanAllLabel.innerText = allLabel;
    }

    $node.appendChild($filterContainerClone);

    listHotels.insertBefore($node, listHotels.firstElementChild);

    return $node;
  },

  handlerAccordionUntilThisElement: (list, { item, itemKey, parentKey, value, listHotels, printDestinationNode }, key) => {
    const currentNode = list.querySelector(`[data-current-element='${itemKey}']`);

    if (printDestinationNode) {
      bcl.s.searchHotel.createFirstElementInHotels(key, item, listHotels, value, parentKey, itemKey);
    }

    bcl.s.searchHotel.openAccordionUntilThisElement(currentNode);

    if (bcl.u.mobile.isMobile()) {
      const parentLiNode = bcl.u.closest(currentNode, "li"),
        childUlNode = parentLiNode?.querySelector("ul");
      bcl.u.forEach(childUlNode.children, ($child) => {
        bcl.u.removeClass($child, "active");
      });
      const accordion = document.querySelector("." + bcl.s.searchHotel.props.openAccordionMobile);
      if (accordion) {
        bcl.u.addClass(accordion, "active");
      }
    }

    bcl.u.addClass(currentNode, "mod--background-grey");
  },

  generateNodeItemInAccordionList: function ($parent, value, label, id, type, { filter }, { hide, parentKey, currentItemKey, setSimple }) {
    let title = label,
      matched = false,
      percentageMatched = 0;

    const labelSplit = value?.split(" ");

    const { title: titleBold, matched: matchedBold } = bcl.s.searchHotel.handlerBold(labelSplit, title, matched);
    title = titleBold;
    matched = matchedBold;

    if (filter && !matched) {
      return false;
    }

    percentageMatched = value ? bcl.s.searchHotel.getPercentageOfMatch(label.split(".")[0], value) : 0;

    const $node = !setSimple
      ? bcl.s.searchHotel.prepareAccordionItem(percentageMatched, hide, id, type, parentKey, currentItemKey, title)
      : bcl.s.searchHotel.prepareLiItem(percentageMatched, { hide, id, type, parentKey, currentItemKey }, title, ["history__hotel-name", "mod--hover-grey"]);

    $parent.appendChild($node);

    return $node;
  },

  getPercentageOfMatch: function (label, value) {
    const currentLabel = bcl.u.removeAccents(label).replace(/-/g, " "),
      valuesWithoutAccents = bcl.u.removeAccents(value.toLocaleLowerCase()).trim().split(" "),
      currentLabelNoSpaceLength = currentLabel.replace(/ /g, "").length;
    let percentage = 0;

    let valuesWithoutAccentsFull = "";
    bcl.u.forEach(valuesWithoutAccents, function (value) {
      valuesWithoutAccentsFull += value;
    });

    const currentLabelCleanedNoAccentsNoSpaces = bcl.u.removeAccents(label).replace(/\s/g, "").toLocaleLowerCase();
    if (currentLabelCleanedNoAccentsNoSpaces.toLocaleLowerCase().indexOf(valuesWithoutAccentsFull.replace(/\s/g, "")) != -1) {
      percentage = 100;
    } else {
      bcl.u.forEach(valuesWithoutAccents, function (value) {
        percentage +=
          ((currentLabelNoSpaceLength -
            currentLabel
              .toLocaleLowerCase()
              .split(value)
              .map((s) => s.trim())
              .join("").length) *
            100) /
          currentLabelNoSpaceLength;
      });
    }
    return percentage;
  },

  parseLevelDestination: function (level) {
    if (level === 0) {
      return "continent";
    }

    if (level === 1) {
      return "country";
    }

    if (level === 2) {
      return "state";
    }

    if (level === 3) {
      return "province";
    }

    if (level === 4) {
      return "city";
    }

    if (level === 5) {
      return "area";
    }
  },

  clickInItem: function (item, id, type) {
    bcl.s.tabs.closeActive();

    let value;

    if (type == "hotel") {
      value = item.hotel.title;
    } else if (type == "destination") {
      value = item.label;
    }

    bcl.s.searchHotel.props.lastLabel = value;
    bcl.s.searchHotel.props.data[id].$searchInput.value = value;
    bcl.s.searchHotel.props.data[id].$searchInput.setAttribute("value", value);

    if (bcl.s.searchHotel.props.data[id].$hotelInput) {
      if (type == "hotel") {
        value = item.hotel.id;
      } else if (type == "destination") {
        if (item.level != undefined) {
          value = bcl.s.searchHotel.parseLevelDestination(item.level) + "-" + item.id;
        } else {
          value = item.id;
        }
      }

      bcl.s.searchHotel.props.data[id].$hotelInput.dataset.type = type;
      bcl.s.searchHotel.props.data[id].$hotelInput.value = value;
      bcl.s.searchHotel.props.data[id].$hotelInput.setAttribute("value", value);
    }

    bcl.s.searchHotel.props.data[id].itemSave = item;

    const $input = bcl.s.searchHotel.props.data[id].$searchInput,
      $form = $input.closest("form");

    bcl.s.form.validateInputStatus($input, $form);
  },
};

bcl.preInit.push(bcl.s.searchHotel.preInit);
