/* eslint-disable @stylistic/js/max-len */
/* eslint-disable @stylistic/js/indent */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable max-len */
/*! hotel-datepicker 3.6.8 - Copyright 2019 Benito Lopez (http://lopezb.com) - https://github.com/benitolopez/hotel-datepicker - MIT */ export var HotelDatepicker = (function () {
  "use strict";
  var t = 0,
    e = window.fecha,
    s = function s(i, a) {
      t || (t = 0), e || (e = window.fecha), (this._boundedEventHandlers = {}), (this.id = s.getNewId());
      var n = a || {};
      (this.format = n.format || "YYYY-MM-DD"),
        (this.infoFormat = n.infoFormat || this.format),
        (this.separator = n.separator || " - "),
        (this.startOfWeek = n.startOfWeek || "sunday"),
        (this.startDate = n.startDate || new Date()),
        (this.endDate = n.endDate || !1),
        (this.minNights = n.minNights || 1),
        (this.maxNights = n.maxNights || 0),
        (this.selectForward = n.selectForward || !1),
        (this.disabledDates = n.disabledDates || []),
        (this.noCheckInDates = n.noCheckInDates || []),
        (this.noCheckOutDates = n.noCheckOutDates || []),
        (this.disabledDaysOfWeek = n.disabledDaysOfWeek || []),
        (this.enableCheckout = n.enableCheckout || !1),
        (this.container = n.container || ""),
        (this.animationSpeed = n.animationSpeed || ".5s"),
        (this.hoveringTooltip = n.hoveringTooltip || !0),
        (this.autoClose = void 0 === n.autoClose || n.autoClose),
        (this.showTopbar = void 0 === n.showTopbar || n.showTopbar),
        (this.moveBothMonths = n.moveBothMonths || !1),
        (this.i18n = n.i18n || {
          selected: "Your stay:",
          night: "Night",
          nights: "Nights",
          button: "Close",
          "checkin-disabled": "Check-in disabled",
          "checkout-disabled": "Check-out disabled",
          "day-names-short": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          "day-names": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          "month-names-short": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          "month-names": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          "error-more": "Date range should not be more than 1 night",
          "error-more-plural": "Date range should not be more than %d nights",
          "error-less": "Date range should not be less than 1 night",
          "error-less-plural": "Date range should not be less than %d nights",
          "info-more": "Please select a date range of at least 1 night",
          "info-more-plural": "Please select a date range of at least %d nights",
          "info-range": "Please select a date range between %d and %d nights",
          "info-default": "Please select a date range",
        }),
        (this.getValue =
          n.getValue ||
          function () {
            return i.value;
          }),
        (this.setValue =
          n.setValue ||
          function (t) {
            i.value = t;
          }),
        (this.onDayClick = void 0 !== n.onDayClick && n.onDayClick),
        (this.onOpenDatepicker = void 0 !== n.onOpenDatepicker && n.onOpenDatepicker),
        (this.onSelectRange = void 0 !== n.onSelectRange && n.onSelectRange),
        (this.input = i),
        this.init();
    };
  return (
    (s.prototype.addBoundedListener = function t(e, s, i, a) {
      e in this._boundedEventHandlers || (this._boundedEventHandlers[e] = {}), s in this._boundedEventHandlers[e] || (this._boundedEventHandlers[e][s] = []);
      var n = i.bind(this);
      this._boundedEventHandlers[e][s].push([n, a]), e.addEventListener(s, n, a);
    }),
    (s.prototype.removeAllBoundedListeners = function t(e, s) {
      if (e in this._boundedEventHandlers) {
        var i = this._boundedEventHandlers[e];
        if (s in i) {
          for (var a = i[s], n = a.length; n--; ) {
            var o = a[n];
            e.removeEventListener(s, o[0], o[1]);
          }
        }
      }
    }),
    (s.getNewId = function e() {
      return ++t;
    }),
    (s.prototype.setFechaI18n = function t() {
      (e.i18n.dayNamesShort = this.i18n["day-names-short"]),
        (e.i18n.dayNames = this.i18n["day-names"]),
        (e.i18n.monthNamesShort = this.i18n["month-names-short"]),
        (e.i18n.monthNames = this.i18n["month-names"]),
        (e.i18n.dayNumbers = this.i18n["day-numbers"]);
    }),
    (s.prototype.getWeekDayNames = function t() {
      var e = "";
      if ("monday" === this.startOfWeek) {
        for (var s = 0; s < 7; s++) {
          e += '<th class="datepicker__week-name">' + this.lang("day-names-short")[(1 + s) % 7] + "</th>";
        }
        return e;
      }
      for (var i = 0; i < 7; i++) {
        e += '<th class="datepicker__week-name">' + this.lang("day-names-short")[i] + "</th>";
      }
      return e;
    }),
    (s.prototype.getMonthDom = function t(e) {
      return document.getElementById(this.getMonthTableId(e));
    }),
    (s.prototype.getMonthName = function t(e) {
      return this.lang("month-names")[e];
    }),
    (s.prototype.getDatepickerId = function t() {
      return "datepicker-" + this.generateId();
    }),
    (s.prototype.getMonthTableId = function t(e) {
      return "month-" + e + "-" + this.generateId();
    }),
    (s.prototype.getCloseButtonId = function t() {
      return "close-" + this.generateId();
    }),
    (s.prototype.getTooltipId = function t() {
      return "tooltip-" + this.generateId();
    }),
    (s.prototype.getNextMonth = function t(e) {
      var s = new Date(e.valueOf());
      return new Date(s.setMonth(s.getMonth() + 1, 1));
    }),
    (s.prototype.getPrevMonth = function t(e) {
      var s = new Date(e.valueOf());
      return new Date(s.setMonth(s.getMonth() - 1, 1));
    }),
    (s.prototype.getDateString = function t(s, i) {
      return void 0 === i && (i = this.format), this.setFechaI18n(), e.format(s, i);
    }),
    (s.prototype.parseDate = function t(s, i) {
      return void 0 === i && (i = this.format), this.setFechaI18n(), e.parse(s, i);
    }),
    (s.prototype.init = function t() {
      (this.parent = this.container ? this.container : this.input.parentElement),
        (this.start = !1),
        (this.end = !1),
        (this.minDays = this.minNights > 1 ? this.minNights + 1 : 2),
        (this.maxDays = this.maxNights > 0 ? this.maxNights + 1 : 0),
        this.startDate && "string" == typeof this.startDate && (this.startDate = this.parseDate(this.startDate)),
        this.endDate && "string" == typeof this.endDate && (this.endDate = this.parseDate(this.endDate)),
        this.isTouchDevice() && (this.hoveringTooltip = !1),
        (this.isOpen = !1),
        (this.changed = !1),
        this.createDom();
      var e = new Date();
      this.startDate && 0 > this.compareMonth(e, this.startDate) && (e = new Date(this.startDate.getTime())),
        this.endDate && this.compareMonth(this.getNextMonth(e), this.endDate) > 0 && (e = new Date(this.getPrevMonth(this.endDate.getTime()))),
        this.disabledDates.length > 0 && this.parseDisabledDates(),
        this.showMonth(e, 1),
        this.showMonth(this.getNextMonth(e), 2),
        this.topBarDefaultText(),
        this.addListeners(),
        (this.isFirstDisabledDate = 0);
    }),
    (s.prototype.addListeners = function t() {
      for (var e = this, s = this.datepicker.getElementsByClassName("datepicker__month-button--next"), i = 0; i < s.length; i++) {
        s[i].addEventListener("click", function (t) {
          return e.goToNextMonth(t);
        });
      }
      for (var a = this.datepicker.getElementsByClassName("datepicker__month-button--prev"), n = 0; n < a.length; n++) {
        a[n].addEventListener("click", function (t) {
          const s = e.goToPreviousMonth(t),
            i = e.month2,
            a = new Date(e.start);
          return !e.end && a && new Date(i.getFullYear() + " " + (i.getMonth() + 1)) < new Date(a.getFullYear() + " " + (a.getMonth() + 1)) && (e.start = NaN), s;
        });
      }
      this.addBoundedListener(this.input, "click", function (t) {
        return e.openDatepicker(t);
      }),
        this.showTopbar &&
          document.getElementById(this.getCloseButtonId()).addEventListener("click", function (t) {
            return e.closeDatepicker(t);
          }),
        this.datepicker.addEventListener("mouseover", function (t) {
          return e.datepickerHover(t);
        }),
        this.datepicker.addEventListener("mouseout", function (t) {
          return e.datepickerMouseOut(t);
        }),
        this.addBoundedListener(this.input, "change", function () {
          return e.checkAndSetDefaultValue();
        });
    }),
    (s.prototype.generateId = function t() {
      return this.input.id ? this.input.id : this.id;
    }),
    (s.prototype.createDom = function t() {
      var e = this.createDatepickerDomString();
      this.parent.insertAdjacentHTML("beforeend", e), (this.datepicker = document.getElementById(this.getDatepickerId()));
    }),
    (s.prototype.createDatepickerDomString = function t() {
      var e = '<div id="' + this.getDatepickerId() + '" style="display:none" class="datepicker datepicker--closed">';
      (e += '<div class="datepicker__inner">'),
        this.showTopbar &&
          (e +=
            '<div class="datepicker__topbar"><div class="datepicker__info datepicker__info--selected"><span class="datepicker__info datepicker__info--selected-label">' +
            this.lang("selected") +
            ' </span> <strong class="datepicker__info-text datepicker__info-text--start-day">...</strong> <span class="datepicker__info-text datepicker__info--separator">' +
            this.separator +
            '</span> <strong class="datepicker__info-text datepicker__info-text--end-day">...</strong> <em class="datepicker__info-text datepicker__info-text--selected-days">(<span></span>)</em></div><div class="datepicker__info datepicker__info--feedback"></div><button type="button" id="' +
            this.getCloseButtonId() +
            '" class="datepicker__close-button">' +
            this.lang("button") +
            "</button></div>"),
        (e += '<div class="datepicker__months">');
      for (var s = 1; s <= 2; s++) {
        e +=
          '<table id="' +
          this.getMonthTableId(s) +
          '" class="datepicker__month datepicker__month--month' +
          s +
          '"><thead><tr class="datepicker__month-caption"><th><span class="datepicker__month-button datepicker__month-button--prev" month="' +
          s +
          '">&lt;</span></th><th colspan="5" class="datepicker__month-name"></th><th><span class="datepicker__month-button datepicker__month-button--next" month="' +
          s +
          '">&gt;</span></th></tr><tr class="datepicker__week-days">' +
          this.getWeekDayNames(s) +
          "</tr></thead><tbody></tbody></table>";
      }
      return (e += "</div>"), (e += '<div style="display:none" id="' + this.getTooltipId() + '" class="datepicker__tooltip"></div>'), (e += "</div>"), (e += "</div>");
    }),
    (s.prototype.showMonth = function t(e, s) {
      var i = this.getMonthName(e.getMonth()),
        a = this.getMonthDom(s),
        n = a.getElementsByClassName("datepicker__month-name")[0],
        o = a.getElementsByTagName("tbody")[0];
      (n.textContent = i + " " + e.getFullYear()), this.emptyElement(o), o.insertAdjacentHTML("beforeend", this.createMonthDomString(e)), this.updateSelectableRange(), (this["month" + s] = e);
    }),
    (s.prototype.createMonthDomString = function t(s) {
      var i,
        a = this,
        n = [],
        o = "";
      s.setDate(1);
      var h = s.getDay(),
        r = s.getMonth();
      if ((0 === h && "monday" === this.startOfWeek && (h = 7), h > 0)) {
        for (var d = h; d > 0; d--) {
          var l = new Date(s.getTime() - 864e5 * d);
          (i = a.isValidDate(l.getTime())), ((a.startDate && 0 > a.compareDay(l, a.startDate)) || (a.endDate && a.compareDay(l, a.endDate) > 0)) && (i = !1), n.push({ date: l, type: "lastMonth", day: l.getDate(), time: l.getTime(), valid: i });
        }
      }
      for (var c = 0; c < 40; c++) {
        var p = a.addDays(s, c);
        (i = a.isValidDate(p.getTime())),
          ((a.startDate && 0 > a.compareDay(p, a.startDate)) || (a.endDate && a.compareDay(p, a.endDate) > 0)) && (i = !1),
          n.push({ date: p, type: p.getMonth() === r ? "visibleMonth" : "nextMonth", day: p.getDate(), time: p.getTime(), valid: i });
      }
      for (var m = 0; m < 6 && "nextMonth" !== n[7 * m].type; m++) {
        o += '<tr class="datepicker__week-row">';
        for (var g = 0; g < 7; g++) {
          var y = "monday" === a.startOfWeek ? g + 1 : g;
          y = n[7 * m + y];
          var u = a.getDateString(y.time) === a.getDateString(new Date()),
            f = a.getDateString(y.time) === a.getDateString(a.startDate),
            D = !1,
            k = !1,
            v = !1,
            $ = !1,
            b = !1,
            _ = !1;
          if (y.valid || "visibleMonth" === y.type) {
            var C = a.getDateString(y.time, "YYYY-MM-DD");
            if (a.disabledDates.length > 0) {
              var M = a.getClosestDates(y.date);
              if (M[0] && M[1] && a.compareDay(y.date, M[0]) && a.countDays(M[0], M[1]) - 2 > 0) {
                var T = a.countDays(M[1], y.date) - 1,
                  w = a.countDays(y.date, M[0]) - 1;
                a.selectForward && T < a.minDays ? (y.valid = !1) : !a.selectForward && T < a.minDays && w < a.minDays && (y.valid = !1), !y.valid && a.enableCheckout && 2 === T && (_ = !0);
              }
              M[0] && 3 === a.countDays(M[0], y.date) && (b = !0), a.disabledDates.indexOf(C) > -1 ? ((y.valid = !1), (D = !0), a.isFirstDisabledDate++) : (a.isFirstDisabledDate = 0);
            }
            a.disabledDaysOfWeek.length > 0 && a.disabledDaysOfWeek.indexOf(e.format(y.time, "dddd")) > -1 && ((y.valid = !1), ($ = !0)),
              a.noCheckInDates.length > 0 && a.noCheckInDates.indexOf(C) > -1 && ((k = !0), (b = !1)),
              a.noCheckOutDates.length > 0 && a.noCheckOutDates.indexOf(C) > -1 && (v = !0);
          }
          var S = [
              "datepicker__month-day--" + y.type,
              "datepicker__month-day--" + (y.valid ? "valid" : "invalid"),
              u ? "datepicker__month-day--today" : "",
              D ? "datepicker__month-day--disabled" : "",
              D && a.enableCheckout && 1 === a.isFirstDisabledDate ? "datepicker__month-day--checkout-enabled" : "",
              _ ? "datepicker__month-day--before-disabled-date" : "",
              f || b ? "datepicker__month-day--checkin-only" : "",
              k ? "datepicker__month-day--no-checkin" : "",
              v ? "datepicker__month-day--no-checkout" : "",
              $ ? "datepicker__month-day--day-of-week-disabled" : "",
            ],
            N = "";
          k && (N = a.i18n["checkin-disabled"]), v && (N && (N += ". "), (N += a.i18n["checkout-disabled"]));
          var x = { time: y.time, class: S.join(" ") };
          N && (x.title = N), (o += '<td class="datepicker__month-day ' + x.class + '" ' + a.printAttributes(x) + ">" + y.day + "</td>");
        }
        o += "</tr>";
      }
      return o;
    }),
    (s.prototype.openDatepicker = function t() {
      var e = this;
      !this.isOpen &&
        (this.removeClass(this.datepicker, "datepicker--closed"),
        this.addClass(this.datepicker, "datepicker--open"),
        this.checkAndSetDefaultValue(),
        this.slideDown(this.datepicker, this.animationSpeed),
        (this.isOpen = !0),
        this.showSelectedDays(),
        this.disableNextPrevButtons(),
        this.addBoundedListener(document, "click", function (t) {
          return e.documentClick(t);
        }),
        this.onOpenDatepicker && this.onOpenDatepicker());
    }),
    (s.prototype.closeDatepicker = function t() {
      if (this.isOpen) {
        this.removeClass(this.datepicker, "datepicker--open"), this.addClass(this.datepicker, "datepicker--closed"), this.slideUp(this.datepicker, this.animationSpeed), (this.isOpen = !1);
        var e = document.createEvent("Event");
        e.initEvent("afterClose", !0, !0), this.input.dispatchEvent(e), this.removeAllBoundedListeners(document, "click");
      }
    }),
    (s.prototype.autoclose = function t() {
      this.autoClose && this.changed && this.isOpen && this.start && this.end && this.closeDatepicker();
    }),
    (s.prototype.documentClick = function t(e) {
      this.parent.contains(e.target) || e.target === this.input ? "td" === e.target.tagName.toLowerCase() && this.dayClicked(e.target) : this.closeDatepicker();
    }),
    (s.prototype.datepickerHover = function t(e) {
      e.target.tagName && "td" === e.target.tagName.toLowerCase() && this.dayHovering(e.target);
    }),
    (s.prototype.datepickerMouseOut = function t(e) {
      e.target.tagName && "td" === e.target.tagName.toLowerCase() && (document.getElementById(this.getTooltipId()).style.display = "none");
    }),
    (s.prototype.checkAndSetDefaultValue = function t() {
      var e = this.getValue(),
        s = e ? e.split(this.separator) : "";
      if (s && s.length >= 2) {
        var i = this.format;
        i.match(/Do/) && ((i = i.replace(/Do/, "D")), (s[0] = s[0].replace(/(\d+)(th|nd|st)/, "$1")), (s[1] = s[1].replace(/(\d+)(th|nd|st)/, "$1"))),
          (this.changed = !1),
          this.setDateRange(this.parseDate(s[0], i), this.parseDate(s[1], i)),
          (this.changed = !0);
      } else {
        this.showTopbar && (this.datepicker.getElementsByClassName("datepicker__info--selected")[0].style.display = "none");
      }
    }),
    (s.prototype.setDateRange = function t(e, s) {
      if (e.getTime() > s.getTime()) {
        var i = s;
        (s = e), (e = i), (i = null);
      }
      var a = !0;
      if ((((this.startDate && 0 > this.compareDay(e, this.startDate)) || (this.endDate && this.compareDay(s, this.endDate) > 0)) && (a = !1), !a)) {
        this.showMonth(this.startDate, 1), this.showMonth(this.getNextMonth(this.startDate), 2), this.showSelectedDays(), this.disableNextPrevButtons();
        return;
      }
      e.setTime(e.getTime() + 432e5),
        s.setTime(s.getTime() + 432e5),
        (this.start = e.getTime()),
        (this.end = s.getTime()),
        this.compareDay(e, s) > 0 && 0 === this.compareMonth(e, s) && (s = this.getNextMonth(e)),
        0 === this.compareMonth(e, s) && (s = this.getNextMonth(e)),
        this.showMonth(e, 1),
        this.showMonth(s, 2),
        this.showSelectedDays(),
        this.disableNextPrevButtons(),
        this.checkSelection(),
        this.showSelectedInfo(),
        this.autoclose();
    }),
    (s.prototype.showSelectedDays = function t() {
      if (this.start || this.end) {
        for (var e = this.datepicker.getElementsByTagName("td"), s = 0; s < e.length; s++) {
          var i = parseInt(e[s].getAttribute("time"), 10);
          (this.start && this.end && this.end >= i && this.start <= i) || (this.start && !this.end && this.getDateString(this.start, "YYYY-MM-DD") === this.getDateString(i, "YYYY-MM-DD"))
            ? this.addClass(e[s], "datepicker__month-day--selected")
            : this.removeClass(e[s], "datepicker__month-day--selected"),
            this.start && this.getDateString(this.start, "YYYY-MM-DD") === this.getDateString(i, "YYYY-MM-DD") ? this.addClass(e[s], "datepicker__month-day--first-day-selected") : this.removeClass(e[s], "datepicker__month-day--first-day-selected"),
            this.end && this.getDateString(this.end, "YYYY-MM-DD") === this.getDateString(i, "YYYY-MM-DD") ? this.addClass(e[s], "datepicker__month-day--last-day-selected") : this.removeClass(e[s], "datepicker__month-day--last-day-selected");
        }
      }
    }),
    (s.prototype.showSelectedInfo = function t() {
      if (!this.showTopbar) {
        if (this.start && this.end) {
          var e = this.getDateString(new Date(this.start)) + this.separator + this.getDateString(new Date(this.end));
          this.setValue(e, this.getDateString(new Date(this.start)), this.getDateString(new Date(this.end))), (this.changed = !0);
        }
        return;
      }
      var s = this.datepicker.getElementsByClassName("datepicker__info--selected")[0],
        i = s.getElementsByClassName("datepicker__info-text--start-day")[0],
        a = s.getElementsByClassName("datepicker__info-text--end-day")[0],
        n = s.getElementsByClassName("datepicker__info-text--selected-days")[0],
        o = document.getElementById(this.getCloseButtonId());
      if (
        ((i.textContent = "..."),
        (a.textContent = "..."),
        (n.style.display = "none"),
        this.start && ((s.style.display = ""), (i.textContent = this.getDateString(new Date(parseInt(this.start, 10)), this.infoFormat))),
        this.end && (a.textContent = this.getDateString(new Date(parseInt(this.end, 10)), this.infoFormat)),
        this.start && this.end)
      ) {
        var h = this.countDays(this.end, this.start) - 1,
          r = 1 === h ? h + " " + this.lang("night") : h + " " + this.lang("nights"),
          d = this.getDateString(new Date(this.start)) + this.separator + this.getDateString(new Date(this.end));
        (n.style.display = ""), (n.firstElementChild.textContent = r), (o.disabled = !1), this.setValue(d, this.getDateString(new Date(this.start)), this.getDateString(new Date(this.end))), (this.changed = !0);
      } else {
        o.disabled = !0;
      }
    }),
    (s.prototype.dayClicked = function t(e) {
      if (!this.hasClass(e, "datepicker__month-day--invalid")) {
        var s = (this.start && this.end) || (!this.start && !this.end);
        if (s) {
          if (this.hasClass(e, "datepicker__month-day--no-checkin")) {
            return;
          }
        } else if (this.start && this.hasClass(e, "datepicker__month-day--no-checkout")) {
          return;
        }
        var i = parseInt(e.getAttribute("time"), 10);
        if ((this.addClass(e, "datepicker__month-day--selected"), s ? ((this.start = i), (this.end = !1)) : this.start && (this.end = i), this.start && this.end && this.start > this.end)) {
          var a = this.end;
          (this.end = this.start), (this.start = a);
        }
        (this.start = parseInt(this.start, 10)),
          (this.end = parseInt(this.end, 10)),
          this.clearHovering(),
          this.start && !this.end && this.dayHovering(e),
          this.updateSelectableRange(),
          this.checkSelection(),
          this.showSelectedInfo(),
          this.showSelectedDays(),
          this.autoclose(),
          this.onDayClick && this.onDayClick(),
          this.end && this.onSelectRange && this.onSelectRange();
      }
    }),
    (s.prototype.isValidDate = function t(e) {
      if (((e = parseInt(e, 10)), (this.startDate && 0 > this.compareDay(e, this.startDate)) || (this.endDate && this.compareDay(e, this.endDate) > 0))) {
        return !1;
      }
      if (this.start && !this.end) {
        if ((this.maxDays > 0 && this.countDays(e, this.start) > this.maxDays) || (this.minDays > 0 && this.countDays(e, this.start) > 1 && this.countDays(e, this.start) < this.minDays) || (this.selectForward && e < this.start)) {
          return !1;
        }
        if (this.disabledDates.length > 0) {
          var s = this.getClosestDates(new Date(parseInt(this.start, 10)));
          if ((s[0] && 0 >= this.compareDay(e, s[0])) || (s[1] && this.compareDay(e, s[1]) >= 0)) {
            return !1;
          }
        }
      }
      return !0;
    }),
    (s.prototype.checkSelection = function t() {
      var e = this.countDays(this.end, this.start),
        s = !!this.showTopbar && this.datepicker.getElementsByClassName("datepicker__info--feedback")[0];
      if (this.maxDays && e > this.maxDays) {
        (this.start = !1), (this.end = !1);
        for (var i = this.datepicker.getElementsByTagName("td"), a = 0; a < i.length; a++) {
          this.removeClass(i[a], "datepicker__month-day--selected"), this.removeClass(i[a], "datepicker__month-day--first-day-selected"), this.removeClass(i[a], "datepicker__month-day--last-day-selected");
        }
        if (this.showTopbar) {
          var n = this.maxDays - 1;
          this.topBarErrorText(s, "error-more", n);
        }
      } else if (this.minDays && e < this.minDays) {
        (this.start = !1), (this.end = !1);
        for (var o = this.datepicker.getElementsByTagName("td"), h = 0; h < o.length; h++) {
          this.removeClass(o[h], "datepicker__month-day--selected"), this.removeClass(o[h], "datepicker__month-day--first-day-selected"), this.removeClass(o[h], "datepicker__month-day--last-day-selected");
        }
        if (this.showTopbar) {
          var r = this.minDays - 1;
          this.topBarErrorText(s, "error-less", r);
        }
      } else {
        this.start || this.end
          ? this.showTopbar && (this.removeClass(s, "datepicker__info--error"), this.removeClass(s, "datepicker__info--help"))
          : this.showTopbar && (this.removeClass(s, "datepicker__info--error"), this.addClass(s, "datepicker__info--help"));
      }
    }),
    (s.prototype.addDays = function t(e, s) {
      var i = new Date(e);
      return i.setDate(i.getDate() + s), i;
    }),
    (s.prototype.countDays = function t(e, s) {
      return Math.abs(this.daysFrom1970(e) - this.daysFrom1970(s)) + 1;
    }),
    (s.prototype.compareDay = function t(e, s) {
      var i = parseInt(this.getDateString(e, "YYYYMMDD"), 10) - parseInt(this.getDateString(s, "YYYYMMDD"), 10);
      return i > 0 ? 1 : 0 === i ? 0 : -1;
    }),
    (s.prototype.compareMonth = function t(e, s) {
      var i = parseInt(this.getDateString(e, "YYYYMM"), 10) - parseInt(this.getDateString(s, "YYYYMM"), 10);
      return i > 0 ? 1 : 0 === i ? 0 : -1;
    }),
    (s.prototype.daysFrom1970 = function t(e) {
      return Math.floor(this.toLocalTimestamp(e) / 864e5);
    }),
    (s.prototype.toLocalTimestamp = function t(e) {
      return "object" == typeof e && e.getTime && (e = e.getTime()), "string" != typeof e || e.match(/\d{13}/) || (e = this.parseDate(e).getTime()), (e = parseInt(e, 10) - 6e4 * new Date().getTimezoneOffset());
    }),
    (s.prototype.printAttributes = function t(e) {
      var s = e,
        i = "";
      for (var a in e) {
        Object.prototype.hasOwnProperty.call(s, a) && (i += a + '="' + s[a] + '" ');
      }
      return i;
    }),
    (s.prototype.goToNextMonth = function t(e) {
      var s = e.target.getAttribute("month"),
        i = s > 1,
        a = i ? this.month2 : this.month1;
      (a = this.getNextMonth(a)),
        !((!this.isSingleMonth() && !i && this.compareMonth(a, this.month2) >= 0) || this.isMonthOutOfRange(a)) && (this.moveBothMonths && i && this.showMonth(this.month2, 1), this.showMonth(a, s), this.showSelectedDays(), this.disableNextPrevButtons());
    }),
    (s.prototype.goToPreviousMonth = function t(e) {
      var s = e.target.getAttribute("month"),
        i = s > 1,
        a = i ? this.month2 : this.month1;
      if (((a = this.getPrevMonth(a)), !((i && 0 >= this.compareMonth(a, this.month1)) || this.isMonthOutOfRange(a)))) {
        var n = new Date(this.month1);
        this.showMonth(a, s), this.moveBothMonths && !i && this.showMonth(n, 2), this.showSelectedDays(), this.disableNextPrevButtons();
      }
    }),
    (s.prototype.isSingleMonth = function t() {
      return !this.isVisible(this.getMonthDom(2));
    }),
    (s.prototype.isMonthOutOfRange = function t(e) {
      var s = new Date(e.valueOf());
      return !!((this.startDate && new Date(s.getFullYear(), s.getMonth() + 1, 0, 23, 59, 59) < this.startDate) || (this.endDate && new Date(s.getFullYear(), s.getMonth(), 1) > this.endDate));
    }),
    (s.prototype.disableNextPrevButtons = function t() {
      if (!this.isSingleMonth()) {
        var e = parseInt(this.getDateString(this.month1, "YYYYMM"), 10),
          s = parseInt(this.getDateString(this.month2, "YYYYMM"), 10),
          i = Math.abs(e - s),
          a = this.datepicker.getElementsByClassName("datepicker__month-button--next"),
          n = this.datepicker.getElementsByClassName("datepicker__month-button--prev");
        i > 1 && 89 !== i
          ? (this.removeClass(a[0], "datepicker__month-button--disabled"), this.removeClass(n[1], "datepicker__month-button--disabled"))
          : (this.addClass(a[0], "datepicker__month-button--disabled"), this.addClass(n[1], "datepicker__month-button--disabled")),
          this.isMonthOutOfRange(this.getPrevMonth(this.month1)) ? this.addClass(n[0], "datepicker__month-button--disabled") : this.removeClass(n[0], "datepicker__month-button--disabled"),
          this.isMonthOutOfRange(this.getNextMonth(this.month2)) ? this.addClass(a[1], "datepicker__month-button--disabled") : this.removeClass(a[1], "datepicker__month-button--disabled");
      }
    }),
    (s.prototype.topBarDefaultText = function t() {
      if (this.showTopbar) {
        var e = "";
        e = this.minDays && this.maxDays ? this.lang("info-range") : this.minDays && this.minDays > 2 ? this.lang("info-more-plural") : this.minDays ? this.lang("info-more") : this.lang("info-default");
        var s = this.datepicker.getElementsByClassName("datepicker__info--feedback")[0];
        (e = e.replace(/%d/, this.minDays - 1).replace(/%d/, this.maxDays - 1)), this.addClass(s, "datepicker__info--help"), this.removeClass(s, "datepicker__info--error"), (s.textContent = e);
      }
    }),
    (s.prototype.topBarErrorText = function t(e, s, i) {
      this.showTopbar &&
        (this.addClass(e, "datepicker__info--error"),
        this.removeClass(e, "datepicker__info--help"),
        i > 1 ? ((s = (s = this.lang(s + "-plural")).replace("%d", i)), (e.textContent = s)) : (s = this.lang(s)),
        (this.datepicker.getElementsByClassName("datepicker__info--selected")[0].style.display = "none"));
    }),
    (s.prototype.updateSelectableRange = function t() {
      for (var e = this.datepicker.getElementsByTagName("td"), s = this.start && !this.end, i = 0; i < e.length; i++) {
        if (
          (this.hasClass(e[i], "datepicker__month-day--invalid") &&
            this.hasClass(e[i], "datepicker__month-day--tmp") &&
            (this.removeClass(e[i], "datepicker__month-day--tmp"),
            this.hasClass(e[i], "datepicker__month-day--tmpinvalid") ? this.removeClass(e[i], "datepicker__month-day--tmpinvalid") : (this.removeClass(e[i], "datepicker__month-day--invalid"), this.addClass(e[i], "datepicker__month-day--valid"))),
          s)
        ) {
          if (
            this.hasClass(e[i], "datepicker__month-day--visibleMonth") &&
            (this.hasClass(e[i], "datepicker__month-day--valid") || this.hasClass(e[i], "datepicker__month-day--disabled") || this.hasClass(e[i], "datepicker__month-day--before-disabled-date"))
          ) {
            var a = parseInt(e[i].getAttribute("time"), 10);
            this.isValidDate(a)
              ? (this.addClass(e[i], "datepicker__month-day--valid"), this.addClass(e[i], "datepicker__month-day--tmp"), this.removeClass(e[i], "datepicker__month-day--invalid"), this.removeClass(e[i], "datepicker__month-day--disabled"))
              : (this.hasClass(e[i], "datepicker__month-day--invalid") && this.addClass(e[i], "datepicker__month-day--tmpinvalid"),
                this.addClass(e[i], "datepicker__month-day--invalid"),
                this.addClass(e[i], "datepicker__month-day--tmp"),
                this.removeClass(e[i], "datepicker__month-day--valid"));
          }
        } else {
          (this.hasClass(e[i], "datepicker__month-day--checkout-enabled") || this.hasClass(e[i], "datepicker__month-day--before-disabled-date")) &&
            (this.addClass(e[i], "datepicker__month-day--invalid"), this.removeClass(e[i], "datepicker__month-day--valid"), this.hasClass(e[i], "datepicker__month-day--before-disabled-date") || this.addClass(e[i], "datepicker__month-day--disabled"));
        }
      }
      return !0;
    }),
    (s.prototype.dayHovering = function t(e) {
      var s = parseInt(e.getAttribute("time"), 10),
        i = "";
      if (!this.hasClass(e, "datepicker__month-day--invalid")) {
        for (var a = this.datepicker.getElementsByTagName("td"), n = 0; n < a.length; n++) {
          var o = parseInt(a[n].getAttribute("time"), 10);
          o === s ? this.addClass(a[n], "datepicker__month-day--hovering") : this.removeClass(a[n], "datepicker__month-day--hovering"),
            this.start && !this.end && ((this.start < o && s >= o) || (this.start > o && s <= o)) ? this.addClass(a[n], "datepicker__month-day--hovering") : this.removeClass(a[n], "datepicker__month-day--hovering");
        }
        if (this.start && !this.end) {
          var h = this.countDays(s, this.start) - 1;
          if (this.hoveringTooltip) {
            if ("function" == typeof this.hoveringTooltip) {
              i = this.hoveringTooltip(h, this.start, s);
            } else if (!0 === this.hoveringTooltip && h > 0) {
              var r = 1 === h ? this.lang("night") : this.lang("nights");
              i = h + " " + r;
            }
          }
        }
      }
      if (i) {
        var d = e.getBoundingClientRect(),
          l = this.datepicker.getBoundingClientRect(),
          c = d.left - l.left,
          p = d.top - l.top;
        c += d.width / 2;
        var m = document.getElementById(this.getTooltipId());
        (m.style.display = ""), (m.textContent = i);
        var g = m.getBoundingClientRect().width,
          y = m.getBoundingClientRect().height;
        (c -= g / 2),
          (p -= y),
          setTimeout(function () {
            (m.style.left = c + "px"), (m.style.top = p + "px");
          }, 10);
      } else {
        document.getElementById(this.getTooltipId()).style.display = "none";
      }
    }),
    (s.prototype.clearHovering = function t() {
      for (var e = this.datepicker.getElementsByTagName("td"), s = 0; s < e.length; s++) {
        this.removeClass(e[s], "datepicker__month-day--hovering");
      }
      document.getElementById(this.getTooltipId()).style.display = "none";
    }),
    (s.prototype.clearSelection = function t() {
      (this.start = !1), (this.end = !1);
      for (var e = this.datepicker.getElementsByTagName("td"), s = 0; s < e.length; s++) {
        this.removeClass(e[s], "datepicker__month-day--selected"), this.removeClass(e[s], "datepicker__month-day--first-day-selected"), this.removeClass(e[s], "datepicker__month-day--last-day-selected");
      }
      this.setValue(""), this.checkSelection(), this.showSelectedInfo(), this.showSelectedDays();
    }),
    (s.prototype.parseDisabledDates = function t() {
      var s = [];
      this.setFechaI18n();
      for (var i = 0; i < this.disabledDates.length; i++) {
        s[i] = e.parse(this.disabledDates[i], "YYYY-MM-DD");
      }
      s.sort(function (t, e) {
        return t - e;
      }),
        (this.disabledDatesTime = s);
    }),
    (s.prototype.getClosestDates = function t(e) {
      var s = [!1, !1];
      if (e < this.disabledDatesTime[0]) {
        s = this.enableCheckout ? [!1, this.addDays(this.disabledDatesTime[0], 1)] : [!1, this.disabledDatesTime[0]];
      } else if (e > this.disabledDatesTime[this.disabledDatesTime.length - 1]) {
        s = [this.disabledDatesTime[this.disabledDatesTime.length - 1], !1];
      } else {
        var i,
          a = this.disabledDatesTime.length,
          n = this.disabledDatesTime.length,
          o = Math.abs(new Date(0, 0, 0).valueOf()),
          h = o,
          r = -o,
          d = 0;
        for (i = 0; i < this.disabledDatesTime.length; ++i) {
          (d = e - this.disabledDatesTime[i]) < 0 && d > r && ((n = i), (r = d)), d > 0 && d < h && ((a = i), (h = d));
        }
        this.disabledDatesTime[a] && (s[0] = this.disabledDatesTime[a]), void 0 === this.disabledDatesTime[a] ? (s[1] = !1) : this.enableCheckout ? (s[1] = this.addDays(this.disabledDatesTime[n], 1)) : (s[1] = this.disabledDatesTime[n]);
      }
      return s;
    }),
    (s.prototype.lang = function t(e) {
      return e in this.i18n ? this.i18n[e] : "";
    }),
    (s.prototype.emptyElement = function t(e) {
      for (; e.firstChild; ) {
        e.removeChild(e.firstChild);
      }
    }),
    (s.prototype.classRegex = function t(e) {
      return RegExp("(^|\\s+)" + e + "(\\s+|$)");
    }),
    (s.prototype.hasClass = function t(e, s) {
      return this.classRegex(s).test(e.className);
    }),
    (s.prototype.addClass = function t(e, s) {
      this.hasClass(e, s) || (e.className = e.className + " " + s);
    }),
    (s.prototype.removeClass = function t(e, s) {
      e.className = e.className.replace(this.classRegex(s), " ");
    }),
    (s.prototype.isVisible = function t(e) {
      return e.offsetWidth || e.offsetHeight || e.getClientRects().length;
    }),
    (s.prototype.slideDown = function t(e, s) {
      e.style.display = "";
      var i = e.getBoundingClientRect().height;
      (e.style.height = 0),
        this.recalc(e.offsetHeight),
        (e.style.transition = "height " + s),
        (e.style.height = i + "px"),
        e.addEventListener("transitionend", function () {
          e.style.height = e.style.transition = e.style.display = "";
        });
    }),
    (s.prototype.slideUp = function t(e, s) {
      var i = e.getBoundingClientRect().height;
      (e.style.height = i + "px"),
        this.recalc(e.offsetHeight),
        (e.style.transition = "height " + s),
        (e.style.height = 0),
        e.addEventListener("transitionend", function () {
          e.style.display = "none";
        });
    }),
    (s.prototype.recalc = function t(e) {
      return e.offsetHeight;
    }),
    (s.prototype.isTouchDevice = function t() {
      return "ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch);
    }),
    (s.prototype.open = function t() {
      this.openDatepicker();
    }),
    (s.prototype.close = function t() {
      this.closeDatepicker();
    }),
    (s.prototype.getDatePicker = function t() {
      return this.datepicker;
    }),
    (s.prototype.setRange = function t(e, s) {
      "string" == typeof e && "string" == typeof s && ((e = this.parseDate(e)), (s = this.parseDate(s))), this.setDateRange(e, s);
    }),
    (s.prototype.clear = function t() {
      this.clearSelection();
    }),
    (s.prototype.getNights = function t() {
      var e = 0;
      if (this.start && this.end) {
        e = this.countDays(this.end, this.start) - 1;
      } else {
        var s = this.getValue(),
          i = s ? s.split(this.separator) : "";
        if (i && i.length >= 2) {
          var a = this.format;
          a.match(/Do/) && ((a = a.replace(/Do/, "D")), (i[0] = i[0].replace(/(\d+)(th|nd|st)/, "$1")), (i[1] = i[1].replace(/(\d+)(th|nd|st)/, "$1"))), (e = this.countDays(this.parseDate(i[0], a), this.parseDate(i[1], a)) - 1);
        }
      }
      return e;
    }),
    (s.prototype.destroy = function t() {
      document.getElementById(this.getDatepickerId()) &&
        (this.removeAllBoundedListeners(this.input, "click"), this.removeAllBoundedListeners(document, "click"), this.removeAllBoundedListeners(this.input, "change"), this.datepicker.parentNode.removeChild(this.datepicker));
    }),
    s
  );
})();
