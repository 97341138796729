/* eslint-disable camelcase */
bcl.u.utag = {
  props: {
    loading: 0,
    loadingAttempts: 0,
    attempts: 0,
    init: false,
    topush: false,
    lastready: false,
    pendding: false,
    queue: [],
    enabledListener: [],
  },

  isEnabled: () => typeof window.utag_data !== "undefined",

  isEnabledUtag: () => typeof utag !== "undefined",

  enableListener: function () {
    if (this.isEnabled()) {
      this.props.loading++;
      this.props.topush = true;
    }
  },

  removeListener: function () {
    if (this.isEnabled()) {
      document.dispatchEvent(new Event("utag-ready"));
    }
  },

  add: function (obj) {
    if (this.isEnabled() && obj) {
      Object.keys(obj).forEach((prop) => {
        if (obj[prop] && (!Array.isArray(obj[prop]) || (Array.isArray(obj[prop]) && obj[prop].length))) {
          window.utag_data[prop] = obj[prop];
        }
      });
    }
  },

  init: function () {
    const props = this.props;
    if (props.pendding) {
      return;
    }
    const currentUrl = window.location.href;
    if (currentUrl.includes("reservation.barcelo.com")) {
      // Prevent executing window.utag.view if the URL contains "https://reservation.barcelo.com/"
      return;
    }

    if ((props.loading <= 0 || props.loadingAttempts >= 5) && props.lastReady && props.topush && this.isEnabledUtag() && this.isEnabled()) {
      props.topush = false;
      window.utag_data.tealium_event = "view";
      window.utag_data["ut.event"] = "view";
      window.utag.view(window.utag_data);

      props.init = true;
      props.queue.forEach((item) => this.push(item));
      props.queue = [];
    }

    if (props.lastReady && !props.init) {
      props.pendding = true;
      props.loadingAttempts++;

      setTimeout(() => {
        props.pendding = false;
        this.init();
      }, 2000);
    }
  },

  push: function (obj) {
    if (this.isEnabled() && obj) {
      if (this.props.init) {
        try {
          this.sendData(obj);
        } catch (e) {
          console.error("Data push error", obj);
          console.error(e);
        }
      } else {
        this.props.queue.push(obj);
      }
    }
  },

  sendData: function (obj) {
    try {
      if (this.isEnabledUtag()) {
        this.props.attempts = 0;
        window.utag.link(obj);
      }
    } catch (e) {
      console.error(e);
      this.props.attempts++;
      if (this.props.attempts <= 10) {
        setTimeout(() => this.sendData(obj), 200);
      }
    }
  },

  loadDataCookies: function () {
    const data = bcl.cookie.get("barcelo_search_uatg_data");
    if (data) {
      this.add(JSON.parse(data));
      bcl.cookie.remove("barcelo_search_uatg_data", "/");
    }
  },
};

bcl.u.docReady(bcl.u.utag.loadDataCookies.bind(bcl.u.utag), true);

document.addEventListener(
  "utag-ready",
  () => {
    bcl.u.utag.props.loading--;
  },
  false,
);
