import ContainerComponents from "barcelo-components/containers";
import AdvComponents from "barcelo-components/adv";
import StructureComponents from "barcelo-components/structure";
import HotelComponents from "barcelo-components/hotel";
import GenericComponents from "barcelo-components/generic";
import ListingComponents from "barcelo-components/listing";
import MenuComponents from "barcelo-components/menu";
import VariousComponents from "barcelo-components/various";
import TeasersComponents from "barcelo-components/teasers";
import FaqComponents from "barcelo-components/faq";
import MyBarceloComponents from "barcelo-components/mybarcelo";

const { container, swiper, accordion, tabsdropdown, tabMenu, launchersContainer } = ContainerComponents;
const { ribbon, languageRibbon, translationEnabledLanguageDialog } = AdvComponents;
const {
  brands,
  megaMenu,
  asideMenu,
  sticky,
  language,
  phoneCC,
  header,
  heroslider,
  hotelMeetingRooms,
  hotelRoyal,
  calendar,
  destination,
  discount,
  highlightsGallery,
  awardsSwiper,
  rooms,
  fastbooking,
  customModal,
  headerDetail,
  campaignGenericComponentGroup,
  localeConfigurationForm,
  footer,
} = StructureComponents;
const { hotelRelated, weather, dorintHotelCards, hotelHighlights } = HotelComponents;
const { cta, socialSharing } = GenericComponents;
const { feedEntries, cardHotelList, offerDestination, promoList, swiperCollectionList, campaignList, collectionsListing } = ListingComponents;
const { stripeMenu, vMenu } = MenuComponents;
const { currencyDialog, opinion, phoneContact, socialRoom, interactiveMap, customChrono, carouselExtras } = VariousComponents;
const { carousel, coupon, servicesCarousel, card, launcher, menuCards, experienceCards } = TeasersComponents;
const { faq, widgetFaq } = FaqComponents;
const { mybOpinion, mybTriggerBanner, mybLoyaltyHero, mybLoyaltyInfo, mybBanner } = MyBarceloComponents;

export default {
  container,
  swiper,
  heroslider,
  accordion,
  tabsdropdown,
  ribbon,
  language,
  languageRibbon,
  translationEnabledLanguageDialog,
  brands,
  megaMenu,
  asideMenu,
  sticky,
  phoneCC,
  header,
  hotelMeetingRooms,
  hotelRoyal,
  calendar,
  destination,
  discount,
  awardsSwiper,
  rooms,
  highlightsGallery,
  fastbooking,
  customModal,
  headerDetail,
  campaignGenericComponentGroup,
  localeConfigurationForm,
  hotelRelated,
  weather,
  cta,
  socialSharing,
  feedEntries,
  cardHotelList,
  offerDestination,
  collectionsListing,
  promoList,
  swiperCollectionList,
  stripeMenu,
  vMenu,
  currencyDialog,
  opinion,
  phoneContact,
  socialRoom,
  interactiveMap,
  customChrono,
  carouselExtras,
  carousel,
  coupon,
  servicesCarousel,
  card,
  launcher,
  faq,
  tabMenu,
  widgetFaq,
  mybOpinion,
  mybTriggerBanner,
  dorintHotelCards,
  hotelHighlights,
  menuCards,
  campaignList,
  mybLoyaltyHero,
  mybLoyaltyInfo,
  mybBanner,
  experienceCards,
  launchersContainer,
  footer,
};
