//----------- NECESSARY FOR FASTBOOKING (HIGHEST PRIORITY) -------------//
import "./js/shared/tabsContainer.js";
import "./js/shared/loadForm.js";

//----------- Initialize Components and Shared dynamically -------------//
import BaseComponents from "./components";
import BarceloComponents from "barcelo-components-init";
import { BarceloShared } from "barcelo-shared-init";

// document.addEventListener("DOMContentLoaded", async function () {
BarceloComponents.init({ ...BaseComponents });
BarceloShared.init();
window.dispatchEvent(new Event("bcl-base-components-loaded"));

//----------- Load Other ClientLibs -------------//

bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo.css");
// bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-personalization.min.css");

bcl.u.loadScript({
  src: "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo.js",
  callback: function () {
    console.log("My barcelo JS loaded");
  },
  type: "text/javascript",
  defer: true,
});

// bcl.u.loadScript({
//   src: "/etc.clientlibs/barcelo/clientlibs/clientlib-personalization.min.js",
//   callback: function () {
//     console.log("Personalization JS loaded");
//   },
//   type: "text/javascript",
//   defer: true,
// });

//----------------------------------------------//
// });

//----------- BARCELO Utilities -------------//
import "./js/utils/cookieUtils.js";
import "./js/utils/utag.js";
import "./js/general.js";

//----------- EMBED CODE Components -------------//
import "../../components/bhg/accordion";
import "../../components/bhg/tabs";

//----------- Shared JavaScript -------------//
import "./js/shared/modal.js";
import "./js/shared/modalMeetingRoom.js";
import "./js/shared/sticky.js";
import "./js/shared/profile.js";
import "./js/shared/progressBar.js";
import "./js/shared/translate-on-the-fly.js";
import "./js/shared/countdown.js";
import "./js/shared/promo-countdown.js";
import "./js/shared/currency.js";
import "./js/shared/dataAnchor.js";
import "./js/shared/hotelReturn.js";
import "./js/shared/scrollUp.js";
import "./js/shared/searchHotel.js";
import "./js/shared/tabs.js";
import "./js/shared/recaptchav3.js";

//----------- My Barcelo Components -------------//
import "../../components/barcelo/mybarcelo/myb-edit-profile/js/c-myb-edit-profile.js";
import "../../components/barcelo/mybarcelo/myb-trigger-banner/js/c-myb-trigger-banner.js";

//----------- Shared JS with Components -------------//
import "./js/shared/tooltip.js";
import "./js/shared/rte-tooltip.js";
import "./js/shared/360.js";
import "./js/shared/flipcard.js";
import "./js/shared/globalEvent.js";

//----------- Vendors -------------//
import "./libraries/datepicker/fecha.min.js";
import "./libraries/datepicker/hotel-datepicker.js";

// Other Components
import "../../components/barcelo/forms/logged-update-form/js/logged-update-form.js";
