bcl.profile = {
  props: {
    className1: "mod--standard",
    className2: "mod--intense",
    className3: "mod--unique",
  },

  placeholderValues: {
    mybDiscount: "myb-discount",
    mybStays: "myb-stays",
    mybLevel: "myb-level",
    mybLeftStays: "myb-left-stays",
    name: "givenName",
    surname: "familyName",
    displayName: "displayName",
    clientCode: "clientCode",
  },
  levelsMyb: {
    1: "Essential",
    2: "Special",
    3: "Unique",
  },

  // add values not in placeholderValues, they will joining
  mapValues: {},

  mergeValues: function () {
    for (const key in bcl.profile.placeholderValues) {
      bcl.profile.mapValues[key] = bcl.profile.placeholderValues[key];
    }
  },

  init: function () {
    bcl.contextHub.callWhenReady(bcl.profile.update, bcl.contextHub.props.nameProfile);
  },

  update: function () {
    bcl.profile.updateClass();
  },

  updateClass: function () {
    const level = bcl.contextHub.getItem(bcl.contextHub.props.nameProfile, bcl.profile.mapValues.mybLevel);

    if (!level) {
      return;
    }

    const name = "className_" + level.toLowerCase();

    bcl.profile.clearClass();

    if (bcl.profile.props[name]) {
      bcl.u.addClass(document.body, bcl.profile.props[name]);
    }
  },

  clearClass: function () {
    bcl.u.removeClass(document.body, bcl.profile.props.className1);
    bcl.u.removeClass(document.body, bcl.profile.props.className2);
    bcl.u.removeClass(document.body, bcl.profile.props.className3);
  },

  parsePlaceholderInContainer: function ($container) {
    const $nodes = $container.querySelectorAll("span[data-placeholder]");

    for (let i = 0; i < $nodes.length; i++) {
      $nodes[i].innerText = bcl.profile.getPlaceholder($nodes[i].getAttribute("data-placeholder"));
    }

    $container.innerHTML = bcl.profile.parsePlaceholder($container.innerHTML);
  },

  parsePlaceholder: function (text) {
    for (const key in bcl.profile.placeholderValues) {
      const value = "[" + key + "]";
      text = text.replace(value, bcl.profile.getPlaceholderWithSpan(key, value));
    }

    return text;
  },

  getPlaceholderWithSpan: function (key) {
    return '<span data-placeholder="' + key + '">' + bcl.profile.getPlaceholder(key) + "</span>";
  },

  getPlaceholder: function (key) {
    return bcl.contextHub.getItem(bcl.contextHub.props.nameProfile, bcl.profile.placeholderValues[key]) || "";
  },

  getLabelLevelMyb: function (num) {
    return this.levelsMyb[num] || null;
  },
};

bcl.profile.mergeValues();
bcl.u.docReady(bcl.profile.init);
