bcl.c.loggedUpdateForm = {
  props: {
    cssSelector: ".c-logged-update-form",
    countrySelector: ".input-JS[name='country']",
    communitySelector: ".input-JS[name='community']",
    provinceSelector: ".input-JS[name='province']",
    sendButtonSelector: ".logged-update-form-button-send-JS",
    disabledButtonClass: "mod--cta-disabled",
    hiddenClass: "hidden",
    anonymousItemsSelector: ".c-logger-update-form-anonymous-view-JS",
    loggedUserItemsSelector: ".c-logger-update-form-logged-view-JS",
    successMessageSelector: ".c-logged-update-form--text-success",
    failureMessageSelector: ".c-logged-update-form--text-error",
  },
  preInit: function () {
    try {
      bcl.u.docReady(bcl.c.loggedUpdateForm.init, true);
    } catch (error) {
      console.error("Error during preInit:", error);
    }
  },

  init: function () {
    try {
      const $component = document.querySelector(bcl.c.loggedUpdateForm.props.cssSelector);
      if (!$component) {
        console.warn("Component not found in bcl.c.loggedUpdateForm");
        return;
      }

      const $country = $component.querySelector("form " + bcl.c.loggedUpdateForm.props.countrySelector);
      const $community = $component.querySelector("form " + bcl.c.loggedUpdateForm.props.communitySelector);
      const $province = $component.querySelector("form " + bcl.c.loggedUpdateForm.props.provinceSelector);
      const $sendButton = $component.querySelector("form " + bcl.c.loggedUpdateForm.props.sendButtonSelector);

      bcl.c.loggedUpdateForm.addEnableNextInput($country, $community);
      bcl.c.loggedUpdateForm.addEnableNextInput($community, $province);
      bcl.c.loggedUpdateForm.addEnableButton($community, $sendButton);

      $sendButton?.addEventListener("click", function () {
        try {
          const $form = $component.querySelector("form");
          const url = $form?.dataset.url;
          if ($country?.value && $community?.value) {
            const data = {
              email: bcl.c.loggedUpdateForm.props.userEmail,
              country: $country.value,
              community: $community.value,
              province: $province.value,
            };
            bcl.ajax.postRequest({
              url: url,
              data: data,
              callback: function (res, $el) {
                try {
                  const $messageElement = $el.querySelector(bcl.c.loggedUpdateForm.props.successMessageSelector);
                  $messageElement?.classList.remove(bcl.c.loggedUpdateForm.props.hiddenClass);
                  bcl.c.loggedUpdateForm.hideAllElements($el);
                } catch (error) {
                  console.error("Error in callback:", error);
                }
              },
              itemCallback: $component,
              errorCallback: bcl.c.loggedUpdateForm.showErrorMessage,
            });
          }
        } catch (error) {
          console.error("Error during click event:", error);
        }
      });

      bcl.u.callWhenLoadUser(bcl.c.loggedUpdateForm.checkIsUserLogged);
    } catch (error) {
      console.error("Error during init:", error);
    }
  },
  showErrorMessage: function ($el) {
    try {
      const $messageElement = $el.querySelector(bcl.c.loggedUpdateForm.props.failureMessageSelector);
      $messageElement?.classList.remove(bcl.c.loggedUpdateForm.props.hiddenClass);
      bcl.c.loggedUpdateForm.hideAllElements($el);
    } catch (error) {
      console.error("Error in showErrorMessage:", error);
    }
  },
  checkIsUserLogged: function () {
    try {
      const user = bcl.u.getContextHubUser();
      if (user && user["myb-email"]) {
        document.querySelectorAll(bcl.c.loggedUpdateForm.props.cssSelector + " " + bcl.c.loggedUpdateForm.props.anonymousItemsSelector).forEach((element) => {
          element.classList.add(bcl.c.loggedUpdateForm.props.hiddenClass);
        });
        document.querySelectorAll(bcl.c.loggedUpdateForm.props.cssSelector + " " + bcl.c.loggedUpdateForm.props.loggedUserItemsSelector).forEach((element) => {
          element.classList.remove(bcl.c.loggedUpdateForm.props.hiddenClass);
        });
        bcl.c.loggedUpdateForm.props.userEmail = user["myb-email"];
      }
    } catch (error) {
      console.error("Error in checkIsUserLogged:", error);
    }
  },
  hideAllElements: function ($component) {
    try {
      $component.querySelectorAll(bcl.c.loggedUpdateForm.props.loggedUserItemsSelector).forEach((element) => {
        element?.classList.add(bcl.c.loggedUpdateForm.props.hiddenClass);
      });
    } catch (error) {
      console.error("Error in hideAllElements:", error);
    }
  },
  disableElement: function (element) {
    try {
      element?.setAttribute("disabled", "true");
    } catch (error) {
      console.error("Error in disableElement:", error);
    }
  },
  enableElement: function (element) {
    try {
      element?.removeAttribute("disabled");
    } catch (error) {
      console.error("Error in enableElement:", error);
    }
  },
  disableButton: function (element) {
    try {
      element?.classList.add(bcl.c.loggedUpdateForm.props.disabledButtonClass);
    } catch (error) {
      console.error("Error in disableButton:", error);
    }
  },
  enableButton: function (element) {
    try {
      element?.classList.remove(bcl.c.loggedUpdateForm.props.disabledButtonClass);
    } catch (error) {
      console.error("Error in enableButton:", error);
    }
  },
  addEnableNextInput: function (currentInput, nextInput) {
    try {
      currentInput?.addEventListener("change", function () {
        try {
          if (currentInput.value === "") {
            nextInput.value = "";
            bcl.c.loggedUpdateForm.disableElement(nextInput);
          } else {
            bcl.c.loggedUpdateForm.enableNextSelectOptions(currentInput, nextInput);
          }
        } catch (error) {
          console.error("Error in addEnableNextInput event handler:", error);
        }
      });
    } catch (error) {
      console.error("Error in addEnableNextInput:", error);
    }
  },
  addEnableButton: function (currentInput, nextInput) {
    try {
      currentInput?.addEventListener("change", function () {
        try {
          if (currentInput.value === "") {
            nextInput.value = "";
            bcl.c.loggedUpdateForm.disableElement(nextInput);
            bcl.c.loggedUpdateForm.disableButton(nextInput.parentElement);
          } else {
            bcl.c.loggedUpdateForm.enableElement(nextInput);
            bcl.c.loggedUpdateForm.enableButton(nextInput.parentElement);
          }
        } catch (error) {
          console.error("Error in addEnableButton event handler:", error);
        }
      });
    } catch (error) {
      console.error("Error in addEnableButton:", error);
    }
  },
  enableNextSelectOptions: function (currentInput, nextInput) {
    try {
      let countEnabledElements = 0;
      const hiddenElements = nextInput.parentElement.querySelector("span.c-logged-update-form--hidden-JS");
      nextInput.querySelectorAll("option").forEach((element) => {
        if (element && element.value !== "") {
          hiddenElements.appendChild(element);
        }
      });
      hiddenElements.querySelectorAll("option").forEach((element) => {
        if (element.value !== "" && currentInput.value === element.dataset.parent) {
          countEnabledElements++;
          nextInput.appendChild(element);
        }
      });

      if (countEnabledElements >= 1) {
        bcl.c.loggedUpdateForm.enableElement(nextInput);
      }
      nextInput.value = "";
      nextInput.dispatchEvent(new Event("change"));
    } catch (error) {
      console.error("Error in enableNextSelectOptions:", error);
    }
  },
};

bcl.preInit.push(bcl.c.loggedUpdateForm.preInit);
